import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User } from '../../api/interfaces/ProjectInterfaces';

interface UserState {
    user: User | null;
}

const initialState: UserState = {
    user: null,
};

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        addUser(state, action: PayloadAction<User>) {
            state.user = action.payload; // Ajoute l'utilisateur au state
        },
        clearUser(state) {
            state.user = null;
        },
    },
});

// Export des actions
export const { addUser, clearUser } = userSlice.actions;

// Sélecteur pour obtenir l'utilisateur
export const selectUser = (state: { user: UserState }) => state.user.user;

export default userSlice.reducer;
