export const ApartmentBg = () => (
    <svg width="800" height="600" viewBox="0 0 800 600" fill="none" xmlns="http://www.w3.org/2000/svg">
        {/* Corps principal de l'immeuble */}
        <path d="M300 100H500V500H300V100Z" fill="#94A3B8" fillOpacity="0.65"/>
        
        {/* Fenêtres */}
        <rect x="350" y="150" width="100" height="50" fill="#94A3B8" fillOpacity="0.75"/>
        <rect x="350" y="250" width="100" height="50" fill="#94A3B8" fillOpacity="0.75"/>
        <rect x="350" y="350" width="100" height="50" fill="#94A3B8" fillOpacity="0.75"/>
        
        {/* Détails architecturaux */}
        <rect x="300" y="100" width="200" height="10" fill="#94A3B8" fillOpacity="0.8"/>
        <rect x="300" y="200" width="200" height="5" fill="#94A3B8" fillOpacity="0.7"/>
        <rect x="300" y="300" width="200" height="5" fill="#94A3B8" fillOpacity="0.7"/>
        <rect x="300" y="400" width="200" height="5" fill="#94A3B8" fillOpacity="0.7"/>
        
        {/* Entrée */}
        <rect x="375" y="450" width="50" height="50" fill="#94A3B8" fillOpacity="0.8"/>
    </svg>
);