import { useSelector } from 'react-redux';
import { selectUser } from '../redux/slices/userSlice';

const usePermission = (permissions: string[]) => {
    const user = useSelector(selectUser);
    const userPermissions = user?.permissions.map((perm: { key: string }) => perm.key) || [];

    return permissions.every(permission => userPermissions.includes(permission));
};

export default usePermission;
