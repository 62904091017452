import React, {useState} from 'react';
import WButton from "../../components/Wbutton";
import {useLocation, useNavigate} from "react-router-dom";
import {TrashIcon, ShareIcon} from '@heroicons/react/16/solid';
import WRemoveProject from "../../components/WRemoveProject";
import PermissionGate from "../../components/PermissionGate";

interface MenuProps {
    projectId: number;
}

const Menu: React.FC<MenuProps> = ({projectId}) => {
    const navigate = useNavigate();
    const location = useLocation();

    const isActive = (path: string) => location.pathname.includes(path);
    const [doRemoveProject, setDoRemoveProject] = useState(false);

    const handleSingleAction = () => {
        navigate(`/project/${projectId}/action`);
    };
    const handleSingleFinancial = () => {
        navigate(`/project/${projectId}/financial`);
    };
    const handleSingleInfo = () => {
        navigate(`/project/${projectId}/info`);
    };

    const handleSuccessRemove = () => {
        setDoRemoveProject(false);
        navigate('/projects');
    }
    const handleRejectRemove = () => setDoRemoveProject(false);
    const handleOpenRemove = () => setDoRemoveProject(true);

    return (
        <nav className="w-full">
            <div className="w-full flex justify-between mt-6">
                <div className="text-left items-stretch">
                    <PermissionGate permissions={['PERMISSION.PROJECT.DETAIL.INFORMATION']}>
                        <WButton variant={isActive('/info') ? 'secondary' : 'primary'}
                                 onClick={handleSingleInfo}
                                 className="ml-2 mt-2 bottom-4 right-3 min-w-[180px]">
                            Informations
                        </WButton>
                    </PermissionGate>

                    <PermissionGate permissions={['PERMISSION.PROJECT.DETAILPL.ANSACTION']}>
                        <WButton variant={isActive('/action') ? 'secondary' : 'primary'}
                                 onClick={handleSingleAction}
                                 className="ml-2 mt-2 bottom-4 right-3 min-w-[180px]">
                            Plans d&apos;action
                        </WButton>
                    </PermissionGate>
                    <PermissionGate permissions={['PERMISSION.PROJECT.ETAIL.AIDESFINANCIERES']}>
                        <WButton variant={isActive('/financial') ? 'secondary' : 'primary'}
                                 onClick={handleSingleFinancial}
                                 className="ml-2 mt-2 bottom-4 right-3 min-w-[180px]">
                            Aides financières
                        </WButton>
                    </PermissionGate>
                    <PermissionGate permissions={['PERMISSION.PROJECT.DETAIL.UTILISATEURS']}>
                        <WButton variant={isActive('/users') ? 'secondary' : 'primary'}
                                 className="ml-2 mt-2 bottom-4 right-3 min-w-[180px]">
                            Utilisateurs
                        </WButton>
                    </PermissionGate>
                </div>
                <div>
                    <PermissionGate permissions={['PERMISSION.PROJECT_DETAIL.DELETE.PROJECT']}>
                        <WButton variant="primary" className="mr-2 mt-2 min-w-[180px]" onClick={handleOpenRemove}>
                            <TrashIcon className="mr-3"/> Effacer
                        </WButton>
                    </PermissionGate>
                    <PermissionGate permissions={['PERMISSION.PROJECT.DETAIL.INVITE']}>
                        <WButton variant="primary" className="mr-2 mt-2 min-w-[180px]"><ShareIcon
                            className="mr-3"/> Partager</WButton>
                    </PermissionGate>
                </div>
            </div>
            {doRemoveProject &&
                <WRemoveProject projectId={projectId} onCancel={handleRejectRemove} onSuccess={handleSuccessRemove}/>}
        </nav>
    );
};

export default Menu;