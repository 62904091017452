import React from 'react';
import WButton from "../../../components/Wbutton"; // Bouton personnalisé utilisé dans le projet
import iconProfil from "../../../assets/icons/profil.svg"; // Icône du profil utilisateur
import WPrice from "../../../components/WPrice"; // Composant affichant les informations de prix
import { ArrowDownTrayIcon } from "@heroicons/react/16/solid"; // Icône pour le téléchargement
import { useNavigate } from "react-router-dom"; // Hook pour la navigation
import Remove from "../Remove"; // Composant pour supprimer un élément (par exemple, un abonnement)
import { useQuery } from "react-query"; // Hook pour gérer les requêtes asynchrones
import { getPlan, IOfferMoment } from "../../../api/payment"; // API pour récupérer les informations des plans
import { infoFacturation } from "../../../api/user"; // API pour les informations de facturation
import {useDispatch, useSelector} from "react-redux"; // Hook pour accéder à l'état Redux
import { selectUser } from "../../../redux/slices/userSlice";
import {logout} from "../../../redux/slices/authSlice";
import WLoader from "../../../components/WLoader/index."; // Sélecteur pour récupérer les informations utilisateur

const Billing: React.FC = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate(); // Permet de naviguer entre les pages
    const user = useSelector(selectUser); // Récupère les données utilisateur depuis le store Redux

    // Fonction pour rediriger vers la page d'informations utilisateur
    const handleGoInfo = () => {
        navigate('/profil/info');
    };

    const handleLogout = () => {
        dispatch(logout());
        navigate('/login');
    };

    // Requête pour récupérer les plans d'abonnement
    const { data: planPrice, isLoading } = useQuery(['getPlanPrice'], async () => {
        return await getPlan(); // Appel à l'API pour les plans
    });

    // Requête pour récupérer les informations de facturation pour l'utilisateur connecté
    const { data: infoFacturationData, isLoading: isLoadingFact } = useQuery(['infoFacturation'], async () => {
        return user && await infoFacturation(user?.id); // Vérifie que l'utilisateur existe avant de faire l'appel
    });

    return (
        <div className="flex flex-col text-left mb-6 relative mx-6">
            {/* Section pour l'en-tête du profil */}
            <div className="flex mt-6 mx-6">
                <img src={iconProfil} alt="Check Icon" className="h-7 text-gray-500" />
                <div>
                    <div className="font-bold text-lg ml-3 mr-3">Mon profil</div>
                </div>
            </div>

            {/* Section pour les boutons Mes infos et Facturation */}
            <div className="flex justify-between mt-6 flex-wrap mx-6">
                <div className="mb-3">
                    <WButton variant="primary" onClick={handleGoInfo} className="bottom-4 right-3 mr-3">
                        Mes infos
                    </WButton>
                    <WButton variant="secondary" className="bottom-4 right-3">
                        Facturation
                    </WButton>
                </div>
                <div>
                    <WButton variant="secondary"
                             onClick={handleLogout}
                             className="bottom-4 right-3 !bg-red-600 !border-red-600">
                        Déconnecter
                    </WButton>
                </div>
            </div>

            {/* Section principale */}
            <div className="bg-white rounded-[10px] shadow-md mt-3 flex flex-col p-6 md:p-12 mx-6">

                {/* Bloc pour afficher l'abonnement actuel */}
                <div className="flex flex-wrap flex-row m-auto">
                    <div className="mr-4">
                        <div className="font-bold border-b-4 border-gray-300 text-center">
                            Votre abonnement actuel
                        </div>
                        <div className="flex gap-4 flex-wrap">
                            {/* Affichage de l'abonnement actuel basé sur l'ID */}
                            {planPrice?.offresDuMoment
                                ?.filter((elm: IOfferMoment) => elm.typeSubscription === infoFacturationData?.abonnementActuel.typeSubscription)
                                .map((elm) => (
                                    <div key={elm.id} className="m-2 mt-8">
                                        <WPrice elm={elm} year={false} isActual={true} />
                                    </div>
                                ))}
                        </div>
                    </div>

                    {/* Bloc pour afficher les offres évolutives */}
                    <div className="ml-4">
                        <div className="font-bold border-b-4 border-green-600 text-center">
                            Faites évoluer votre solution
                        </div>
                        <div className="flex gap-4 flex-wrap">
                            {planPrice?.offresDuMoment
                                ?.filter((elm: IOfferMoment) => elm.typeSubscription !== infoFacturationData?.abonnementActuel.typeSubscription)
                                .map((elm) => (
                                    <div key={elm.id} className="m-2 mt-8">
                                        <WPrice elm={elm} year={false} />
                                    </div>
                                ))}
                        </div>
                    </div>
                </div>

                {/* Moyen de paiement */}
                <div className="font-bold mt-10 mb-3">Moyen de paiement</div>
                <div className="flex flex-row justify-between border-2 border-[#FAFBFD] bg-gray-100 leading-10 px-6 py-2">
                    <div>
                        {infoFacturationData?.moyenDePaiement[0]?.typeMoyenDePaiement} XXXX XXXX XXXX{" "}
                        {infoFacturationData?.moyenDePaiement[0]?.dernierChiffreMoyenDePaiement}
                    </div>
                    <div className="underline">Retirer</div>
                </div>

                {/* Prochaine échéance */}
                <div className="font-bold mt-10 mb-3">Prochaine échéance</div>
                <div className="flex flex-row justify-between border-2 border-[#FAFBFD] bg-gray-100 leading-10 px-6 py-2">
                    <div>
                        {infoFacturationData?.dateProchaineEcheance &&
                            new Date(infoFacturationData.dateProchaineEcheance.replace(' +0000', 'Z')).toLocaleDateString()}
                    </div>
                    <div>
                        Montant :{" "}
                        <span className="text-[#60AD23] font-medium">
                            {infoFacturationData?.montantProchaineEcheance} €
                        </span>
                    </div>
                </div>

                {/* Historique des factures */}
                <div className="font-bold mt-10 mb-3">Historique de factures</div>
                {infoFacturationData?.historiqueFacture?.map((fac, index) => (
                    <div
                        key={index}
                        className={`flex flex-row justify-between border-2 ${
                            index % 2 === 0 ? "bg-gray-100" : "bg-white"
                        } border-[#FAFBFD] leading-10 px-6 py-2`}
                    >
                        <div className="flex">
                            {new Date(fac.dateFacturation.replace(' +0000', 'Z')).toLocaleDateString()}
                            <span className="ml-8">Facture N° : {fac.numFacture}</span>
                        </div>
                        <div className="flex items-center justify-center">
                            Montant :{" "}
                            <span className="text-[#60AD23] font-medium ml-3">
                                {(fac.prixTtc / 100).toFixed(2)} €
                            </span>
                            <a
                                href={fac.pdf}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="ml-3 text-gray-400"
                            >
                                <ArrowDownTrayIcon className="h-5" />
                            </a>
                        </div>
                    </div>
                ))}

                <hr className="mb-6 mt-20" />
                <Remove />
            </div>
            {(isLoading || isLoadingFact) &&  <WLoader message="Chargement" />}
        </div>
    );
};

export default Billing;
