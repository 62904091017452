import React, {useState} from 'react';
import WButton from "../../../components/Wbutton";
import WInput from "../../../components/WInput";
import {useDispatch, useSelector} from "react-redux";
import {addUser, selectUser} from "../../../redux/slices/userSlice";
import {useMutation, useQuery} from 'react-query';
import {getUserInfoById, updateUser} from "../../../api/user";
import {User} from "../../../api/interfaces/ProjectInterfaces";
import iconProfil from "../../../assets/icons/profil.svg";
import {useNavigate} from "react-router-dom";
import Remove from "../Remove";
import WToast from "../../../components/WToast";
import {useTranslation} from "react-i18next";
import {logout} from "../../../redux/slices/authSlice";
import WLoader from "../../../components/WLoader/index.";
import PermissionGate from "../../../components/PermissionGate";

const Info: React.FC = () => {
    const user = useSelector(selectUser);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {t} = useTranslation();

    // État local pour stocker les informations utilisateur
    const [formData, setFormData] = useState(user);
    const [confirmPassword, setConfirmPassword] = useState("");
    const [showToast, setShowToast] = useState(false);

    const handleChangePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
        setConfirmPassword(e.target.value);
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target;

        if (formData && name in formData) {
            setFormData({...formData, [name]: value});
        }
    };

    const {mutate} = useMutation<User, Error, User>(updateUser, {
        onSuccess: (data) => {
            dispatch(addUser(data));
            setShowToast(true);
        },
        onError: (error) => {
            console.error(error);
        },
    });

    const { isLoading } = useQuery(
        ["userInfo", user?.id],
        async () => {
            const uu = (user?.id) ? await getUserInfoById(user?.id) : null;
            if(uu && user) {
                uu.id = user?.id;
            }

            setFormData(uu);
        }
    );

    const handleSubmit = () => {
        if (formData && (confirmPassword && confirmPassword === formData.password)) {
            //mutate(formData);
        }

        formData && mutate(formData);
    };

    const handleGoBilling = () => {
        navigate('/profil/billing');
    };

    const handleLogout = () => {
        dispatch(logout());
        navigate('/login');
    };

    if (!formData) {
        return <></>
    }

    return (<div className="flex flex-col text-left mb-6 w-full max-w-[1000px] relative mx-6">
            <div className="flex mt-6 mx-6">
                <img src={iconProfil} alt="Check Icon" className="h-7 text-gray-500"/>
                <div>
                    <div className="font-bold text-lg  ml-3 mr-3">Mon profil</div>
                </div>
            </div>
            <div className="flex justify-between mt-6 flex-wrap mx-6">
                <div className="mb-3">
                    <WButton variant="secondary" className="bottom-4 right-3 mr-3">Mes infos</WButton>
                    <PermissionGate permissions={['PERMISSION.USERS.INFORMATION.VIEW.INFOFACTURATION']}>
                        <WButton variant="primary" onClick={handleGoBilling} className="bottom-4 right-3">Facturation</WButton>
                    </PermissionGate>
                </div>
                <div>
                    <WButton variant="secondary" onClick={handleLogout}
                             className="bottom-4 right-3 !bg-red-600 !border-red-600">Déconnecter</WButton>
                </div>
            </div>

            {/* ... */}
            <div className="bg-white rounded-[10px] shadow-md mt-3 flex flex-col p-6 md:p-12 mx-6">
                <div className="font-bold">Informations personnelles</div>
                <div className="flex gap-4 mt-6 flex-wrap">
                    <WInput placeholder="Prénom" name="prenom" value={formData.prenom || ""} onChange={handleChange}
                            required/>
                    <WInput placeholder="Nom" name="nom" value={formData.nom || ""} onChange={handleChange}
                            required/>
                </div>
                <div className="flex gap-4 mt-6 flex-wrap">
                    <WInput name="email" placeholder="E-mail" value={formData.email || ""} onChange={handleChange}/>
                    <WInput name="mobile" placeholder="N° de téléphone" value={formData.mobile || ""}
                            onChange={handleChange} />
                </div>
                <div className="flex gap-6 mt-12">
                    <WInput placeholder="Mot de passe" name="password" type="password" value={formData.password}
                            onChange={handleChange} required/>
                </div>
                <div className="flex gap-4 mt-6">
                    <WInput placeholder="Confirmez votre mot de passe" name="confirmPassword" type="password"
                            onChange={handleChangePassword} value={confirmPassword} required/>
                </div>
                <div className="flex gap-4 mt-10">
                <WInput placeholder="Rôle" disabled={true} name="role" value={t(formData.role)} onChange={handleChange}  required/>
                </div>
                {formData.afficherInfosFacturation && <><div className="flex font-bold mt-10">Informations de facturation</div>
                <div className="flex flex-col gap-4 mt-6 flex-wrap">
                    <WInput placeholder="Société" value={formData.societe || ""} name="societe" onChange={handleChange}  required/>
                    <WInput placeholder="SIRET" name="siret" value={formData.siret || ""} onChange={handleChange}  required/>
                    <WInput placeholder="Adresse" name="adresse" value={formData.adresse || ""} onChange={handleChange}  required/>
                </div>
                <div className="flex gap-4 mt-6 flex-wrap">
                    <WInput placeholder="Ville" name="ville" value={formData.ville || ""} onChange={handleChange}  required/>
                    <WInput placeholder="Code postal" name="codePostal" value={formData.codePostal || ""} onChange={handleChange}  required/>
                </div></>}
                <div className="flex justify-center pt-12">
                    <WButton variant="secondary" onClick={handleSubmit}>Enregistrer les modifications</WButton>
                </div>
                <hr className="mb-6 mt-20 " />
                <Remove />
            </div>
            {(isLoading) &&  <WLoader message="Chargement" />}
            {showToast && (
                <WToast
                    message="Enregistré avec succès"
                    type="success"
                    onClose={() => setShowToast(false)} // Masque le toast après fermeture
                />
            )}
        </div>
    );
};

export default Info;
