import React from "react";

interface WLoaderProps {
    type?: 'calculation' | 'analysis' | 'generation' | 'saving' | 'loading';
    message?: string;
    subMessage?: string;
}

const WLoader: React.FC<WLoaderProps> = ({ 
    type = 'loading',
    message,
    subMessage 
}) => {
    const defaultMessages = {
        calculation: "Calcul des économies d'énergie",
        analysis: "Analyse de votre habitat",
        generation: "Génération de votre rapport",
        saving: "Sauvegarde de vos données",
        loading: "Chargement en cours..."
    };

    const icons = {
        calculation: (
            <div className="w-16 h-16 animate-spin">
                <svg viewBox="0 0 24 24" className="text-green-500">
                    <path 
                        fill="none" 
                        stroke="currentColor" 
                        strokeWidth="2"
                        d="M12 3v3m0 12v3M3 12h3m12 0h3m-2.5-7.5l-2 2m-7 7l-2 2m11 0l-2-2m-7-7l-2-2"
                    />
                </svg>
            </div>
        ),
        analysis: (
            <div className="flex space-x-2">
                <div className="w-3 h-12 bg-green-500 rounded-full animate-pulse"></div>
                <div className="w-3 h-12 bg-green-500 rounded-full animate-pulse delay-100"></div>
                <div className="w-3 h-12 bg-green-500 rounded-full animate-pulse delay-200"></div>
            </div>
        ),
        generation: (
            <div className="w-12 h-12 border-4 border-green-500 rounded-full border-t-transparent animate-spin">
            </div>
        ),
        saving: (
            <div className="flex items-center space-x-2">
                <div className="w-4 h-4 bg-green-500 rounded-full animate-ping"></div>
            </div>
        ),
        loading: (
            <div className="flex space-x-3">
                <div className="w-4 h-4 bg-green-500 rounded-full animate-bounce"></div>
                <div className="w-4 h-4 bg-green-500 rounded-full animate-bounce" style={{ animationDelay: '0.1s' }}></div>
                <div className="w-4 h-4 bg-green-500 rounded-full animate-bounce" style={{ animationDelay: '0.2s' }}></div>
            </div>
        )
    };

    return (
        <div className="fixed inset-0 bg-white/90 backdrop-blur-sm flex items-center justify-center z-50">
            <div className="flex flex-col items-center p-8 max-w-md animate-fadeIn">
                {/* Icône animée */}
                <div className="text-green-500 mb-8">
                    {icons[type]}
                </div>

                {/* Message principal */}
                <h2 className="text-2xl font-medium text-gray-800 text-center mb-2">
                    {message || defaultMessages[type]}
                </h2>

                {/* Sous-message */}
                {subMessage && (
                    <p className="text-gray-500 text-center">
                        {subMessage}
                    </p>
                )}

                {/* Barre de progression */}
                <div className="w-full max-w-xs h-1 bg-gray-100 rounded-full mt-8 overflow-hidden">
                    <div className="h-full bg-green-500 animate-progress"></div>
                </div>
            </div>
        </div>
    );
};

export default WLoader;