export const HouseBg = () => (
    <svg width="800" height="600" viewBox="0 0 800 600" fill="none" xmlns="http://www.w3.org/2000/svg">
        {/* Toit principal */}
        <path d="M400 50L650 200H150L400 50Z" fill="#94A3B8" fillOpacity="0.65"/>
        
        {/* Corps principal de la maison */}
        <path d="M200 200H600V450H200V200Z" fill="#94A3B8" fillOpacity="0.6"/>
        
        {/* Garage */}
        <path d="M150 300H300V450H150V300Z" fill="#94A3B8" fillOpacity="0.55"/>
        <path d="M150 280L300 280L300 300L150 300L150 280Z" fill="#94A3B8" fillOpacity="0.65"/>
        
        {/* Fenêtres */}
        <rect x="250" y="250" width="80" height="100" rx="2" fill="#94A3B8" fillOpacity="0.7"/>
        <rect x="470" y="250" width="80" height="100" rx="2" fill="#94A3B8" fillOpacity="0.7"/>
        
        {/* Porte d'entrée */}
        <path d="M360 320H440V450H360V320Z" fill="#94A3B8" fillOpacity="0.75"/>
        <circle cx="425" cy="385" r="5" fill="#94A3B8" fillOpacity="0.8"/>
        
        {/* Cheminée */}
        <rect x="500" y="100" width="30" height="60" fill="#94A3B8" fillOpacity="0.65"/>
        
        {/* Détails du toit */}
        <path d="M400 50L420 65L380 65L400 50Z" fill="#94A3B8" fillOpacity="0.75"/>
        
        {/* Allée */}
        <path d="M360 450L440 450L480 550L320 550L360 450Z" fill="#94A3B8" fillOpacity="0.5"/>
        
        {/* Petits détails décoratifs */}
        <rect x="270" y="280" width="40" height="40" fill="#94A3B8" fillOpacity="0.75"/>
        <rect x="490" y="280" width="40" height="40" fill="#94A3B8" fillOpacity="0.75"/>
    </svg>
);