import React, {useState, useEffect} from 'react';
import dpeA from "../../assets/icons/DPE/DPE A.svg";
import dpeB from "../../assets/icons/DPE/DPE B.svg";
import dpeC from "../../assets/icons/DPE/DPE C.svg";
import dpeD from "../../assets/icons/DPE/DPE D.svg";
import dpeE from "../../assets/icons/DPE/DPE E.svg";
import dpeF from "../../assets/icons/DPE/DPE F.svg";
import dpeG from "../../assets/icons/DPE/DPE G.svg";
import {useQuery} from "react-query";
import {getPlanAction} from "../../api/project";
import WButton from "../Wbutton";
import iconProject from "../../assets/icons/icon optim/iso-ext.svg";
import iconWind from "../../assets/icons/icon optim/iso-menuiseries.svg";
import iconVent from "../../assets/icons/icon optim/vlc.svg";
import iconChauf from "../../assets/icons/icon optim/chauffage.svg";

interface DPESelectorProps {
    projectId: number;
    onSelect: (objective: string) => void;
    excludeObjectives?: string[];
}

const DPESelector: React.FC<DPESelectorProps> = ({
                                                     projectId,
                                                     onSelect,
                                                     excludeObjectives = [],
                                                 }) => {
    const [selectedObjective, setSelectedObjective] = useState<string>();
    const {data: planActionData} = useQuery(['planAction'], async () => {
        if (!projectId) return null;
        return await getPlanAction(projectId);
    }, {enabled: !!projectId});

    const dpeImages: { [key: string]: string } = {
        A: dpeA,
        B: dpeB,
        C: dpeC,
        D: dpeD,
        E: dpeE,
        F: dpeF,
        G: dpeG,
    };

    const filteredPlanActionData = planActionData?.filter(
        (plan) => !excludeObjectives.includes(plan.classeEnergetiqueObjectique)
    )?.sort((a, b) =>
        a.classeEnergetiqueObjectique.localeCompare(b.classeEnergetiqueObjectique)
    );

    useEffect(() => {
        if (
            filteredPlanActionData &&
            filteredPlanActionData.length > 0 &&
            (!selectedObjective ||
                !filteredPlanActionData.some(
                    (p) => p.classeEnergetiqueObjectique === selectedObjective
                ))
        ) {
            setSelectedObjective(filteredPlanActionData[0].classeEnergetiqueObjectique);
        }
    }, [filteredPlanActionData, selectedObjective]);

    const handleObjectiveChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedObjective(e.target.value);
    };

    const handleSelect = () => {
        selectedObjective && onSelect(selectedObjective);
    };

    const selectPlan = planActionData?.find(p => p.classeEnergetiqueObjectique === selectedObjective);

    return (
        <div className="flex flex-col shadow-md border rounded-lg w-full justify-start items-start mt-3">
            <div className="flex flex-row flex-wrap items-stretch mx-3 md:mx-10 my-3">
                <div className="flex md:flex-1 flex-col w-full md:min-w-[300px] mt-8 md:mr-6">
                    {selectedObjective && <>
                        <img
                            src={dpeImages[selectedObjective]}
                            alt={`DPE ${selectedObjective}`}
                            className="max-h-40 mb-6"
                        />
                        <select
                            id="objectif-dpe"
                            name="objectif-dpe"
                            onChange={handleObjectiveChange}
                            value={selectedObjective}
                            className="block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm sm:text-sm"
                        >
                            {filteredPlanActionData?.map((plan, i) => (
                                <option
                                    key={i}
                                    value={plan.classeEnergetiqueObjectique}
                                >
                                    Objectif DPE : {plan.classeEnergetiqueObjectique}
                                </option>
                            ))}
                        </select>

                        <div className="flex flex-col mt-8 text-sm text-left">
                            {selectPlan?.information}
                        </div>
                        <div className="font-bold text-left text-lg mt-3">{selectPlan?.economieEnergie} kWhep / An</div>
                    </>}
                </div>
                <div className="flex md:flex-1 flex-col w-full md:min-w-[300px] mt-8 md:mr-6">
                    <div className="font-bold text-lg">Travaux à prévoir :</div>

                    {selectPlan?.travaux.map((trav, index) => {

                        const iconMap: Record<string, string> = {
                            "Isolation intérieurs murs exposés": iconProject,
                            "Isolation extérieure murs exposés": iconProject,
                            "Pose double vitrage": iconWind,
                            "Ventillation": iconVent,
                            "Chauffage": iconChauf,
                        };

                        return (
                            <React.Fragment key={index}>
                                <div
                                    className="border-dotted border-t-2 mt-6 border-gray-300"></div>
                                <div className="flex space-x-4">
                                    <img src={iconMap[trav.libelle]} className="h-6 relative top-3"
                                         alt="Icon"/>
                                    <p className="text-[#7C90AA] mt-3 text-sm">{trav.libelle}</p>
                                </div>
                            </React.Fragment>
                        );
                    })}
                </div>
                <div className="flex md:flex-1 flex-col w-full md:min-w-[300px] mt-8">
                    <div className="font-bold text-lg">Estimations :</div>
                    <div className="border-dotted border-t-2 mt-6 border-gray-300"></div>
                    <p className="text-[#7C90AA] mt-3 text-sm">Surface perdue : <span
                        className="font-bold"> {selectPlan?.surfacePerdue}</span></p>
                    <div className="border-dotted border-t-2 mt-6 border-gray-300"></div>
                    <p className="text-[#7C90AA] mt-3 text-sm">Prévision d’économies d’énergie :</p>
                    <p className="text-[#5FBB46] font-medium text-2xl mt-2">{selectPlan?.economieEnergie}</p>
                    <div className="border-dotted border-t-2 mt-6 border-gray-300"></div>
                    <p className="text-[#7C90AA] mt-3 text-sm">Estimation de budget (htva) :</p>
                    <p className="text-[#5FBB46] font-medium text-2xl mt-2">{selectPlan?.budget}</p>
                    <div className="border-dotted border-t-2 mt-6 border-gray-300"></div>
                    <p className="text-[#7C90AA] mt-3 text-sm">Estimation du montant des aides :</p>
                    <p className="text-[#5FBB46] font-medium text-2xl mt-2">{selectPlan?.montantAides}</p>
                    <div className="border-dotted border-t-2 mt-6 border-gray-300"></div>
                    <p className="text-[#7C90AA] mt-3 text-sm">Estimation du reste à charge :</p>
                    <p className="text-[#5FBB46] font-medium text-2xl mt-2">{selectPlan?.resteACharge}</p>
                </div>
                <div className="flex w-full justify-start mb-6 mt-3">
                    <WButton
                        variant="secondary"
                        onClick={handleSelect}
                        className="px-4 mt-6 py-2"
                    >
                        Valider et calculer
                    </WButton>
                </div>
            </div>
        </div>
    );
};

export default DPESelector;
