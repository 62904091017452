import React from "react";
import {IWButtonProps} from "./types";
import './styles.css';

const WButton: React.FC<IWButtonProps> = ({children, variant = 'primary', className = "",href, ...props}) => {
    const handleClick = () => {

        if (href) {
            window.open(href, '_blank');
        }
    };
    return (
        <button
            className={`w-btn rounded-lg border-2 font-normal py-2 px-4 focus:outline-none focus:ring-2 
        ${variant === 'primary' ? 'btn-primary' : variant === 'secondary' ? 'btn-secondary' :
                    variant === 'tertiary' ? 'btn-tertiary' : ''}
        ${className}`}
            onClick={handleClick}
            {...props}
        >
            {children}
        </button>
    );
};

export default WButton;

