import React, { useState } from 'react';
import './styles.css';

interface WSwitchProps {
    onChange: (isOn: boolean) => void;
    defaultState?: boolean;
}

const WSwitch: React.FC<WSwitchProps> = ({ onChange, defaultState = false }) => {
    const [isOn, setIsOn] = useState<boolean>(defaultState);

    const toggleSwitch = () => {
        const newState = !isOn;
        setIsOn(newState);
        onChange(newState);
    };

    return (
        <button
            onClick={toggleSwitch}
            className={`WSwitch relative inline-flex items-center cursor-pointer w-12 h-6 rounded-full transition-colors duration-300 ease-in-out ${
                isOn ? 'bg-green-500' : 'bg-red-500'
            }`}
        >
            <span
                className={`absolute block w-6 h-6 bg-white rounded-full transition-transform duration-300 ease-in-out transform ${
                    isOn ? 'translate-x-6' : 'translate-x-0'
                }`}
            />
        </button>
    );

};

export default WSwitch;
