import React from 'react';
import { useNavigate } from 'react-router-dom';
import WButton from "../../../components/Wbutton";
import WInfo from "../../../components/WInfo";

const ConfirmValidEmail: React.FC = () => {
    const navigate = useNavigate();
    const handleLoginRedirect = () => {
        navigate('/login');
    };

    return (
        <div className="flex flex-col w-svw justify-center items-center max-w-[700px] m-auto">
            <WInfo>Votre adresse e-mail est vérifiée. Merci !</WInfo>

            <div className="font-bold text-lg mt-3">C’est parti !</div>
            <div className="font-normal">Connectez-vous et créer votre premier projet</div>

            <div className="flex gap-6 mt-16 m-auto flex-col">
                <WButton variant="secondary" onClick={handleLoginRedirect}>
                    Connectez-vous
                </WButton>
            </div>
        </div>
    );
};

export default ConfirmValidEmail;
