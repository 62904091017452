import React from 'react';
import { colorDPE } from "../../constants";

// Fonction pour convertir hex en rgba avec opacité
const hexToRgba = (hex: string, opacity: number): string => {
    const bigint = parseInt(hex.slice(1), 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
};

// Définition des types pour les props du composant
interface DescriptionLoiClimatProps {
    lettre: keyof typeof colorDPE;  // La lettre doit être l'une des clés de l'objet `colorDPE`
}

// Composant qui affiche la description de la loi en fonction de la lettre
const DescriptionLoiClimat: React.FC<DescriptionLoiClimatProps> = ({ lettre }) => {
    // Texte associé à chaque lettre avec des éléments de liste pour les puces
    const textes: { [key: string]: JSX.Element } = {
        G: (
            <>
                <p className="mb-3">
                    Selon la <a href="https://wattless.fr/tout-savoir-sur-la-loi-climat-et-resilience/" target="_blank" rel="noopener noreferrer" className="text-blue-600 underline">Climat et Résilience</a>, le bien est soumis à :
                </p>
                <ul className="list-disc ml-5 text-left">
                    <li>Un gel des loyers en vigueur depuis Janvier 2024</li>
                    <li>Un audit énergétique obligatoire en cas de vente depuis Janvier 2024</li>
                    <li>Une interdiction de mise en location à partir de Janvier 2025</li>
                </ul>
                <p>Pour y remédier, des rénovations sont à entreprendre.</p>
            </>
        ),
        F: (
            <>
                <p className="mb-3">
                    Selon la <a href="https://wattless.fr/tout-savoir-sur-la-loi-climat-et-resilience/" target="_blank" rel="noopener noreferrer" className="text-blue-600 underline">Climat et Résilience</a>, le bien est soumis à :
                </p>
                <ul className="list-disc ml-5 text-left">
                    <li>Un gel des loyers en vigueur depuis Janvier 2024</li>
                    <li>Un audit énergétique obligatoire en cas de vente depuis Janvier 2024</li>
                    <li>Une interdiction de mise en location à partir de Janvier 2028</li>
                </ul>
                <p>Pour y remédier, des rénovations sont à entreprendre.</p>
            </>
        ),
        E: (
            <>
                <p className="mb-3">
                    Selon la <a href="https://wattless.fr/tout-savoir-sur-la-loi-climat-et-resilience/" target="_blank" rel="noopener noreferrer" className="text-blue-600 underline">Climat et Résilience</a>, le bien est soumis à :
                </p>
                <ul className="list-disc ml-5 text-left">
                    <li>Un audit énergétique obligatoire en cas de vente depuis Janvier 2025</li>
                    <li>Une interdiction de mise en location à partir de Janvier 2034</li>
                </ul>
                <p>Pour y remédier, des rénovations sont à entreprendre.</p>
            </>
        ),
        D: (
            <>
                <p className="mb-3">
                    Selon la <a href="https://wattless.fr/tout-savoir-sur-la-loi-climat-et-resilience/" target="_blank" rel="noopener noreferrer" className="text-blue-600 underline">Climat et Résilience</a>, le bien est soumis à :
                </p>
                <ul className="list-disc ml-5 text-left">
                    <li>Un audit énergétique obligatoire en cas de vente à partir de Janvier 2034</li>
                </ul>
                <p>Pour y remédier, des rénovations sont à entreprendre.</p>
            </>
        ),
        C: (
            <>
                <p>
                    Selon la <a href="https://wattless.fr/tout-savoir-sur-la-loi-climat-et-resilience/" target="_blank" rel="noopener noreferrer" className="text-blue-600 underline">Climat et Résilience</a>, le bien n’est soumis à aucune réglementation énergétique contraignante pour sa mise en location ou sa mise en vente.
                </p>
                <p>Toutefois, il peut être intéressant pour des raisons économiques, écologiques et de valorisation du patrimoine d’effectuer des rénovations énergétiques.</p>
            </>
        ),
        B: (
            <>
                <p>
                    Selon la <a href="https://wattless.fr/tout-savoir-sur-la-loi-climat-et-resilience/" target="_blank" rel="noopener noreferrer" className="text-blue-600 underline">Climat et Résilience</a>, le bien n’est soumis à aucune réglementation énergétique contraignante pour sa mise en location ou sa mise en vente.
                </p>
                <p>Toutefois, il peut être intéressant pour des raisons économiques, écologiques et de valorisation du patrimoine d’effectuer des rénovations énergétiques.</p>
            </>
        ),
        A: (
            <>
                <p>
                    Selon la <a href="https://wattless.fr/tout-savoir-sur-la-loi-climat-et-resilience/" target="_blank" rel="noopener noreferrer" className="text-blue-600 underline">Climat et Résilience</a>, le bien n’est soumis à aucune réglementation énergétique contraignante pour sa mise en location ou sa mise en vente.
                </p>
                <p>A noter que votre logement se trouve dans la meilleure classe énergétique possible d’après les normes actuelles. Félicitation !</p>
            </>
        ),
    };

    // Calcul de la couleur en fonction de la lettre
    const backgroundColor = hexToRgba(colorDPE[lettre], 0.1);
    const borderColor = colorDPE[lettre];

    // Description du texte
    const description = textes[lettre] || "Lettre invalide. Veuillez fournir une lettre entre A et G.";
    const action = "Aucune action spécifiée.";

    return (
        <div
            className="flex flex-col text-sm shadow-md p-5 border-2 border-dotted items-center rounded-[10px] m-3 text-center justify-center h-1/2"
            style={{
                backgroundColor: backgroundColor,
                borderColor: borderColor,
            }}
        >
            {/* Texte avec des puces */}
            <div className="mb-10 text-left">{description}</div>

            {/* Action */}
            <span>{action}</span>
        </div>
    );
};

export default DescriptionLoiClimat;
