import React, {useEffect, useState} from 'react';
import {useMutation} from 'react-query';
import {useLocation, useNavigate} from "react-router-dom";
import {payment, IPaymentRequest, IResponsePayment, IOfferMoment, verifyMail} from '../../../api/payment';
import WInput from "../../../components/WInput";
import WButton from "../../../components/Wbutton";
import WCheckbox from "../../../components/WCheckbox";
import {ChevronLeftIcon, ChevronRightIcon} from "@heroicons/react/16/solid";
import WInfo from "../../../components/WInfo";
import {useTranslation} from "react-i18next";
import './styles.css';
import StripeForm from "./StripeForm";
import {loadStripe} from "@stripe/stripe-js";
import {Elements} from "@stripe/react-stripe-js";

const stripePromise = process.env.REACT_APP_STRIPE_PUBLIC_KEY ?
    loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY) : null;

if (!stripePromise) {
    console.error("Stripe public key is not defined in the environment variables.");
}

interface LocationState {
    priceId: string;
    subscriptionID: number;
    price: string;
    libelle: string;
    year: boolean;
    pack: IOfferMoment;
}

const PaymentForm: React.FC = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const state = location.state as LocationState | null;

    useEffect(() => {
        // Redirection si location.state est null
        if (!state) {
            navigate('/pricing-plans');
        }
    }, [state, navigate]);

    const {priceId, subscriptionID, libelle, year, pack} = location.state as LocationState;
    const {t} = useTranslation();
    const [clientSecret, setClientSecret] = useState<string | null>(null);
    const [paymentData, setPaymentData] = useState<IResponsePayment | null>(null);
    const [isTermsAccepted, setIsTermsAccepted] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [passwordError, setPasswordError] = useState<string | null>(null);
    const [formData, setFormData] = useState<IPaymentRequest>({
        subscriptionID: subscriptionID,
        email: "",
        nom: "",
        prenom: "",
        adresse: "",
        ville: "",
        codePostal: "",
        mobile: "",
        societe: "",
        siret: "",
        secretKey: "",
        priceID: priceId,
        couponID: "",
    });

    const [passwords, setPasswords] = useState({
        password: '',
        confirmPassword: ''
    });

    // Configurer la mutation
    const mutation = useMutation<IResponsePayment, Error, IPaymentRequest>((paymentData) => payment(paymentData),
        {
            onSuccess: async (data) => {
                setClientSecret(data.clientSecret);
                setPaymentData(data);
            },
            onError: (error) => {
                console.error("Erreur de paiement : ", error);
            }
        }
    );

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };

    const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target;
        setPasswords((prevPasswords) => ({
            ...prevPasswords,
            [name]: value
        }));
    };

    const isValidPhoneNumber = (phone: string): boolean => {       
        const phoneRegex = /^(?:(?:\+|00)33|0)\s*[67](?:[\s.-]*\d{2}){4}$/;
        return phoneRegex.test(phone);
    };

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();

        if (!formData.nom) {
            setErrorMessage(t('required-nom'));
            return;
        }

        if (!formData.prenom) {
            setErrorMessage(t('required-prenom'));
            return;
        }

        if (!formData.mobile) {
            setErrorMessage(t('required-mobile'));
            return;
        }

        if (!formData.societe) {
            setErrorMessage(t('required-societe'));
            return;
        }

        if (!formData.siret) {
            setErrorMessage(t('required-siret'));
            return;
        }

        if (!formData.adresse) {
            setErrorMessage(t('required-adresse'));
            return;
        }

        if (!formData.ville) {
            setErrorMessage(t('required-ville'));
            return;
        }

        if (!formData.codePostal) {
            setErrorMessage(t('required-codePostal'));
            return;
        }

        if (!isTermsAccepted) {
            setErrorMessage(t('terms-error'));
            return;
        }

        if(formData)

        if (passwords.password != "" && passwords.password != undefined && passwords.password !== passwords.confirmPassword) {
            setPasswordError(t('password-mismatch'));
            return;
        }

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!formData.email || !emailRegex.test(formData.email)) {
            setErrorMessage(t('invalid-email'));
            return;
        }

        // Validation du format
        if (formData.mobile && !isValidPhoneNumber(formData.mobile)) {
            setErrorMessage(t('invalid-phone'));
            return;
        } else {
            setErrorMessage(null);
        }

        const response = await verifyMail(formData.email);
        
        if (!response) {
            setErrorMessage(t('email-already-exists'));
            return;
        }else{
            setPasswordError(null);
            setErrorMessage(null);
    
            // Ajouter le mot de passe dans formData avant d'envoyer
            setFormData((prevData) => ({
                ...prevData,
                secretKey: passwords.password
            }));
    
            mutation.mutate(formData);
        }

    };

    if (!formData) {
        return <div>loading</div>;
    }

    return (<div className="flex-row w-svw justify-center items-center max-w-[700px] m-auto">
        {clientSecret && paymentData &&
            <Elements stripe={stripePromise} options={{clientSecret: clientSecret}} >
                <StripeForm plan={formData} year={year} payment={paymentData} pack={pack} />
            </Elements>}

        <div className="font-bold text-lg mt-3">{t('title-form')}</div>
        <div className="font-normal">{t('details')}</div>

        <WInfo>
            Votre choix : {libelle} - Abonnement {year ? 'annuel' : 'mensuel'} {year
            ? `${pack.prixAnnuelHT} €/an`
            : `${pack.prixMensuelHT} €/mois`}
        </WInfo>

        <div className="flex flex-col mt-8 pf-form-container mb-8">
            {/* User */}
            <div className="flex gap-6 flex-wrap">
                <WInput placeholder={t('ph-firstname')} name="prenom" value={formData.prenom}
                        onChange={handleChange} required={true}/>
                <WInput placeholder={t('ph-lastname')} name="nom" value={formData.nom} onChange={handleChange}
                        required/>
            </div>
            <div className="flex gap-6 mt-6 flex-wrap">
                <WInput placeholder={t('ph-email')} name="email" value={formData.email} onChange={handleChange}
                        required/>
                <WInput placeholder={t('ph-phone')} name="mobile" value={formData.mobile} onChange={handleChange}
                        required/>
            </div>

            {/* Password */}

            <div className="flex gap-6 mt-6 flex-wrap mb-6">
                <WInput
                    type="password"
                    placeholder={t('ph-password')}
                    name="password"
                    value={passwords.password}
                    onChange={handlePasswordChange}
                    required
                />
                <WInput
                    type="password"
                    placeholder={t('ph-confirm-password')}
                    name="confirmPassword"
                    value={passwords.confirmPassword}
                    onChange={handlePasswordChange}
                    required
                />
            </div>
            {passwordError && <div className="text-left text-sm text-red-500 mt-2">{passwordError}</div>}

            {/* Company */}
            <div className="flex gap-6 mt-6 flex-wrap">
                <WInput placeholder={t('ph-company')} name="societe" value={formData.societe}
                        onChange={handleChange} required/>
                <WInput placeholder={t('ph-siret')} name="siret" value={formData.siret} onChange={handleChange}
                        required/>
            </div>
            <div className="flex gap-6 mt-12 flex-wrap">
                <WInput placeholder={t('ph-addr')} name="adresse" value={formData.adresse} onChange={handleChange}
                        required/>
            </div>
            <div className="flex gap-6 mt-6 flex-wrap">
                <WInput placeholder={t('ph-city')} name="ville" value={formData.ville} onChange={handleChange}
                        required/>
                <WInput placeholder={t('ph-postal')} name="codePostal" value={formData.codePostal}
                        onChange={handleChange} required/>
            </div>

            <div className="flex gap-6 mt-12">
                <WCheckbox id="acceptTerms" checked={isTermsAccepted}
                           onChange={() => setIsTermsAccepted(!isTermsAccepted)}
                           label={<a rel="noreferrer" href="https://wattless.fr/conditions-generales/" target="_blank">{t('terms-label')}<span className="text-red-500">*</span></a>} />
            </div>

            {errorMessage && <div className="text-left text-sm text-red-500 mt-2">{errorMessage}</div>}

            <div className="flex justify-between gap-6 mt-16 flex-wrap">
                <WButton variant="primary" className="btn-form" onClick={() => navigate('/pricing-plans')}>
                    <ChevronLeftIcon/>{"Précédent"}
                </WButton>
                <WButton variant="secondary" className="btn-form" onClick={handleSubmit}>
                    {"Payer"}<ChevronRightIcon/>
                </WButton>
            </div>
        </div>
    </div>);
};

export default PaymentForm;
