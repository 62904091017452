import React, { useState } from "react";

interface MultiSelectProps<T> {
    items: T[]; // Liste des éléments génériques
    keyExtractor: (item: T) => string | number; // Fonction pour extraire une clé unique
    labelExtractor: (item: T) => string; // Fonction pour extraire un label
    onSelectionChange?: (selectedItems: T[]) => void; // Callback pour les éléments sélectionnés
}

const WMultiSelect = <T,>({
                             items,
                             keyExtractor,
                             labelExtractor,
                             onSelectionChange,
                         }: MultiSelectProps<T>) => {
    const [selectedItems, setSelectedItems] = useState<T[]>([]);
    const [isOpen, setIsOpen] = useState(false);

    const handleCheckboxChange = (item: T) => {
        const isSelected = selectedItems.includes(item);

        const updatedSelection = isSelected
            ? selectedItems.filter((i) => i !== item)
            : [...selectedItems, item];

        setSelectedItems(updatedSelection);

        // Appeler le callback avec les éléments sélectionnés
        if (onSelectionChange) {
            onSelectionChange(updatedSelection);
        }
    };

    const toggleDropdown = () => setIsOpen(!isOpen);

    const getSelectedLabels = () =>
        selectedItems.map((item) => labelExtractor(item)).join(", ");

    return (
        <div className="relative w-full">
            {/* Select Box */}
            <div
                className="block w-full px-4 py-2 border rounded-md shadow-sm bg-white cursor-pointer focus:ring-blue-500 focus:border-blue-500"
                onClick={toggleDropdown}
            >
                {selectedItems.length > 0
                    ? getSelectedLabels()
                    : "Sélectionnez des éléments"}
            </div>

            {/* Dropdown */}
            {isOpen && (
                <div className="absolute mt-1 w-full border rounded-md bg-white shadow-lg z-10">
                    <ul className="max-h-60 overflow-y-auto p-2 space-y-2">
                        {items.map((item) => {
                            const key = keyExtractor(item);
                            const label = labelExtractor(item);

                            return (
                                <li key={key} className="flex items-center">
                                    <input
                                        type="checkbox"
                                        id={`item-${key}`}
                                        className="h-4 w-4 text-blue-500 border-gray-300 rounded focus:ring-blue-500"
                                        checked={selectedItems.includes(item)}
                                        onChange={() => handleCheckboxChange(item)}
                                    />
                                    <label
                                        htmlFor={`item-${key}`}
                                        className="ml-2 text-gray-700 cursor-pointer"
                                    >
                                        {label}
                                    </label>
                                </li>
                            );
                        })}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default WMultiSelect;
