import React from 'react';
import { HouseBg } from '../../assets/icons/components/HouseBg';
import { ApartmentBg } from '../../assets/icons/components/ApartmentBg';

interface WBackgroundImageProps {
    type: string;
    className?: string;
}

const WBackgroundImage: React.FC<WBackgroundImageProps> = ({ type, className = '' }) => {
    const isHouse = type.toLowerCase().includes('maison');
    
    return (
        <div className={`absolute inset-0 w-full h-full overflow-hidden ${className}`}>
            {isHouse ? <HouseBg /> : <ApartmentBg />}
        </div>
    );
};

export default WBackgroundImage;