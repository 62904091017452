import React, { useEffect, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectAccount } from "../../../redux/slices/accountSlice";
import { useMutation } from "react-query";
import { createAccount } from "../../../api/payment";

const ConfirmPayment: React.FC = () => {
    const navigate = useNavigate();
    const account = useSelector(selectAccount);

    const hasMutatedRef = useRef(false);  // Utiliser useRef pour éviter des appels répétés

    const { mutate } = useMutation(createAccount, {
        onSuccess: () => {
            console.log("Account created successfully!");
            navigate('/valid-email'); // Redirect directly to email confirmation page
        },
        onError: (error) => {
            console.error("Error creating account:", error);
        }
    });

    useEffect(() => {
        if (account && !hasMutatedRef.current) {
            hasMutatedRef.current = true;  // Marquer que la mutation a été effectuée
            mutate(account);
        }
    }, [account, mutate]);

    return (
        <div className="flex flex-col w-svw justify-center items-center max-w-[700px] m-auto">
            <div className="font-bold text-lg mt-6 ml-3 mr-3">
                Votre paiement est en cours de traitement...
            </div>
            <div className="font-normal m-auto ml-3 mr-3">
                Veuillez patienter, vous serez bientôt redirigé vers la page de confirmation de votre e-mail.
            </div>
        </div>
    );
};

export default ConfirmPayment;
