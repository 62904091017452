import React from 'react';
import RectA from "../../assets/icons/RectangleA.svg";
import RectB from "../../assets/icons/RectangleB.svg";
import RectC from "../../assets/icons/RectangleC.svg";
import RectD from "../../assets/icons/RectangleD.svg";
import RectE from "../../assets/icons/RectangleE.svg";
import RectF from "../../assets/icons/RectangleF.svg";
import RectG from "../../assets/icons/RectangleG.svg";
import RectGray from "../../assets/icons/RectangleGray.svg";

// Définir le type des props pour le composant
interface WObjectiveProps {
    level: string; // Niveau actuel de l'utilisateur (A à G)
    objective: string; // Objectif actuel de l'utilisateur (A à G)
}

const sizeTriangle = 18;

const WObjective: React.FC<WObjectiveProps> = ({ level, objective }) => {
    const levels = ['A', 'B', 'C', 'D', 'E', 'F', 'G'];
    let indTriangle = 0;

    return (
        <div className="block w-[250px] rounded-lg overflow-hidden relative">
            <div className="flex relative w-max pl-[16px] left-[-10px]">
                {levels.reverse().map((currentLevel) => {
                    indTriangle++;

                    return (
                        <div
                            key={currentLevel}
                            style={{
                                left: `-${indTriangle * sizeTriangle}px`,
                                zIndex: 80 - indTriangle * 10, // Z-Index défini ici
                                position: 'relative'
                            }}
                        >
                            {/* Affichage dynamique de l'image (colorée si niveau/objectif atteint, grisée sinon) */}
                            <img
                                src={(currentLevel === level || currentLevel === objective) ? getRectImage(currentLevel) : RectGray}
                                style={{ zIndex: 70 }} // Z-Index de l'image
                                alt={`rect-${currentLevel.toLowerCase()}`}
                            />
                            <span
                                style={{ zIndex: 80, left: '23px', top: '5px', position: 'absolute' }} // Z-Index du texte
                                className="font-bold text-white text-xl"
                            >
                                {currentLevel}
                            </span>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

// Fonction utilitaire pour obtenir la bonne image en fonction du niveau
const getRectImage = (level: string): string => {
    switch (level) {
        case 'A':
            return RectA;
        case 'B':
            return RectB;
        case 'C':
            return RectC;
        case 'D':
            return RectD;
        case 'E':
            return RectE;
        case 'F':
            return RectF;
        case 'G':
            return RectG;
        default:
            return RectGray;
    }
};

export default WObjective;
