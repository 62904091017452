import React from 'react';
import usePermission from '../../hooks/usePermission';

interface PermissionGateProps {
    permissions: string[];
    children: React.ReactNode;
}

const PermissionGate: React.FC<PermissionGateProps> = ({ permissions, children }) => {
    const hasPermission = usePermission(permissions);

    return hasPermission ? <>{children}</> : null;
};

export default PermissionGate;
