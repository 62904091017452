import React, {useState} from 'react';
import WButton from "../../../components/Wbutton";
import {useParams} from "react-router-dom";
import {useMutation, useQuery} from "react-query";
import {
    financialParams, getFinancialAssistance, getStatusDemandeur,
    getUsageActif, RecalculeFinancialAssistance, UpdateFinancialAssistance
} from "../../../api/project";
import {useSelector} from "react-redux";
import {selectProjectById} from "../../../redux/slices/projectSlice";
import {useTranslation} from "react-i18next";
import WLoader from "../../../components/WLoader/index.";
import WToast from "../../../components/WToast";
import Menu from "../menu";
import PermissionGate from "../../../components/PermissionGate";
import usePermission from "../../../hooks/usePermission";

const SingleFinancial: React.FC = () => {

    const hasPermissionUpdate = usePermission(['PERMISSION.PROJECT.ETAIL.AIDESFINANCIERES.UPDATE']);
    /* Récuperation ID param */
    const {id} = useParams<{ id: string }>();
    const numericId = id ? parseInt(id) : null;
    const currentProject = useSelector(selectProjectById(numericId));

    const {t} = useTranslation();
    const [showToast, setShowToast] = useState(false);
    const [status, setStatus] = useState('');
    const [usage, setUsage] = useState('');
    const [ecoLoan, setEcoLoan] = useState(true);
    const [revenuFiscal, setRevenuFiscal] = useState(0);
    const [nombrePersonnes, setNombrePersonnes] = useState(0);

    const {mutate} = useMutation(async ({projectId, financialParams}: {
        projectId: number, financialParams: financialParams
    }) => {
        await UpdateFinancialAssistance(projectId, financialParams);
        await RecalculeFinancialAssistance(projectId);
    }, {
        onSuccess: () => {
            setShowToast(true);
        }
    });

    const {data: getFinancialAssistanceData} = useQuery(['getFinancialAssistance'], async () => {
        if (numericId) {
            const data = await getFinancialAssistance(numericId);

            setEcoLoan(data.dejaBeneficierEcoPret);
            setRevenuFiscal(data.dernierRevenuFiscalReference);
            setNombrePersonnes(data.nbrPersonnesDeclarees);
            setStatus(data.statusDemandeur);
            setUsage(data.usageActif);

            return data;
        }

        return false;
    });

    const {data: usageActif} = useQuery(['usageActif'], async () => {
        return await getUsageActif();
    });

    const {data: statusDemandeur} = useQuery(['statusDemandeur'], async () => {
        return await getStatusDemandeur();
    });

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        if (currentProject?.id) {
            mutate({
                projectId: currentProject.id,
                financialParams: {
                    statusDemandeur: status,
                    usageActif: usage,
                    dernierRevenuFiscalReference: revenuFiscal,
                    nbrPersonnesDeclarees: nombrePersonnes,
                    dejaBeneficierEcoPret: ecoLoan
                }
            });
        }
    };

    if (!currentProject || !getFinancialAssistanceData) {
        return <WLoader message={"Chargement"}/>;
    }

    return <div className="w-full max-w-[1200px]">
        <div className="w-full flex justify-between mt-6">
            <Menu projectId={currentProject.id}/>
        </div>
        <div className="text-left mb-6">
            <div className="flex mt-12 mx-6">
                <div
                    className="text-[19px] leading-7 text-[#5FBB46] border-4 border-[#899BB4] font-bold h-9 w-9 text-center rounded-full">€
                </div>
                <div className="w-[calc(100%-2.25rem)]">
                    <div className="font-bold text-lg ml-3 mr-3">Aides financières</div>
                </div>
            </div>

            <div className="bg-white rounded-[10px] shadow-md mx-6 relative mt-12">
                <div className="flex flex-col p-6 md:p-12 ">

                    <form onSubmit={handleSubmit} className="space-y-10">

                        {/* Status du demandeur */}
                        <div>
                            <h2 className="font-bold text-black text-base mb-2">
                                Status du demandeur par rapport au bien objet de la demande :
                                <span className="text-red-500">*</span>
                            </h2>
                            <div className="flex flex-wrap m-8">
                                {statusDemandeur && statusDemandeur.map(
                                    (elm, index) =>
                                        <label key={index}
                                               className="flex items-center text-base font-normal mr-4">
                                            <input
                                                disabled={!hasPermissionUpdate}
                                                type="radio"
                                                name="status"
                                                value={elm}
                                                checked={status === elm}
                                                required={true}
                                                onChange={(e) => setStatus(e.target.value)}
                                                className="mr-2 border border-gray-400 bg-gray-100 rounded-md"
                                            />
                                            {t(elm)}
                                        </label>
                                )}
                            </div>
                        </div>

                        <div className="border-dashed border-t border-gray-400 my-14"></div>

                        {/* Usage du bien */}
                        <div>
                            <h2 className="font-bold text-black text-base mb-2">
                                Usage du bien objet par la demande :
                                <span className="text-red-500">*</span>
                            </h2>
                            <div className="flex flex-wrap m-8">
                                {usageActif && usageActif.map(
                                    (elm, index) =>
                                        <label key={index}
                                               className="flex items-center text-base font-normal mr-4">
                                            <input
                                                disabled={!hasPermissionUpdate}
                                                type="radio"
                                                name="usage"
                                                value={elm}
                                                checked={usage === elm}
                                                required={true}
                                                onChange={(e) => setUsage(e.target.value)}
                                                className="mr-2 border border-gray-400 bg-gray-100 rounded-md"
                                            />
                                            {t(elm)}
                                        </label>
                                )}
                            </div>
                        </div>

                        <div className="border-dashed border-t border-gray-400 my-6"></div>

                        {/* Dernier revenu fiscal de référence */}
                        <div>
                            <h2 className="font-bold text-black text-base mb-2 inline">
                                Dernier revenu fiscal de référence du ménage :
                                <span className="text-red-500">*</span>
                            </h2>
                            <div className="flex flex-wrap m-8">
                                <input
                                    disabled={!hasPermissionUpdate}
                                    type="number"
                                    id="revenuFiscal"
                                    name="revenuFiscal"
                                    value={revenuFiscal}
                                    onChange={(e) => setRevenuFiscal(Number(e.target.value))}
                                    placeholder="Euros"
                                    required={true}
                                    className="mx-3 border-[#899BB433] p-2 border rounded-lg shadow-sm bg-[#F5F6FA]"
                                />
                                <span className="items-center text-base font-normal inline">Euros</span>
                            </div>
                        </div>

                        <div className="border-dashed border-t border-gray-400 my-6"></div>

                        {/* Nombre de personnes déclarées */}
                        <div>
                            <h2 className="font-bold text-black text-base mb-2 inline">
                                Nombre de personnes déclarées sur votre dernier avis d’impôt :
                                <span className="text-red-500">*</span>
                            </h2>
                            <div className="flex flex-wrap m-8">
                                <input
                                    disabled={!hasPermissionUpdate}
                                    type="number"
                                    id="nombrePersonnes"
                                    name="nombrePersonnes"
                                    value={nombrePersonnes}
                                    onChange={(e) => setNombrePersonnes(Number(e.target.value))}
                                    required={true}
                                    placeholder="Nombre de personnes"
                                    className="mx-3 border-[#899BB433] p-2 border rounded-lg shadow-sm w-[200px] bg-[#F5F6FA]"
                                />
                            </div>
                        </div>

                        <div className="border-dashed border-t border-gray-400"></div>

                        {/* Eco-Prêt à taux zéro */}
                        <div>
                            <h2 className="font-bold text-black text-base mb-2">
                                Est-ce que le demandeur a déjà bénéficié d’un Eco-Prêt à taux zéro pour votre
                                logement ?
                                <span className="text-red-500">*</span>
                            </h2>
                            <div className="flex space-x-4 m-8">
                                <label className="flex items-center text-base font-medium cursor-pointer">
                                    <input
                                        disabled={!hasPermissionUpdate}
                                        type="radio"
                                        name="ecoLoan"
                                        checked={ecoLoan}
                                        onChange={() => setEcoLoan(true)}
                                        required={true}
                                        className="appearance-none w-5 h-5 rounded-full border-2 border-gray-300 checked:border-blue-500 checked:bg-blue-500 transition duration-200"
                                    />
                                    <span className="ml-3">Oui</span>
                                </label>
                                <label className="flex items-center text-base font-medium cursor-pointer">
                                    <input
                                        disabled={!hasPermissionUpdate}
                                        type="radio"
                                        name="ecoLoan"
                                        checked={!ecoLoan}
                                        onChange={() => setEcoLoan(false)}
                                        required={true}
                                        className="appearance-none w-5 h-5 rounded-full border-2 border-gray-300 checked:border-blue-500 checked:bg-blue-500 transition duration-200"
                                    />
                                    <span className="ml-3">Non</span>
                                </label>
                            </div>
                        </div>
                    </form>
                    {/* Submit button */}
                    <div className="flex justify-center mt-15">
                        <PermissionGate permissions={['PERMISSION.PROJECT.ETAIL.AIDESFINANCIERES.UPDATE']}>
                            <WButton variant="secondary" onClick={handleSubmit}
                                     className=" bottom-4 right-3">Enregistrer les modifications et recalculer</WButton>
                        </PermissionGate>

                    </div>
                </div>
            </div>
        </div>
        {showToast && (
            <WToast
                message="Enregistré avec succès"
                type="success"
                onClose={() => setShowToast(false)} // Masque le toast après fermeture
            />
        )}
    </div>
};

export default SingleFinancial;