import axios from './axiosInstance';
import {User} from "./interfaces/ProjectInterfaces";
import {SubscriptionData} from "./interfaces/ApiInterfaces";

export const getUser = async (): Promise<User[]> => {
    const response = await axios.get(`/users`);
    return response.data.data;
};

export const getUserById = async (userId: number): Promise<User> => {
    const response = await axios.get(`/users/${userId}`);
    return response.data;
};

export const getUsers = async (): Promise<User[]> => {
    const response = await axios.get(`/users`);
    return response.data;
};

export const codeVerificationEmail = async (code: string): Promise<boolean> => {
    const response = await axios.post(`/users/verificationmail/${code}/valid`, null);
    return response.data;
};

export const updateUser = async (user: User): Promise<User> => {
    const response = await axios.post(`/users/${user.id}/informations`, user);
    return response.data.data;
};



export const deleteUser = async (userId: number): Promise<User> => {
    const response = await axios.delete(`/users/${userId}`);
    return response.data.data;
};

/** Détail **/
export const getUserInfoById = async (userId: number): Promise<User> => {
    const response = await axios.get(`/users/${userId}/informations`);
    return response.data.data;
};

export const infoFacturation = async (userId: number): Promise<SubscriptionData> => {
    const response = await axios.get(`/users/${userId}/infofacturation`);
    return response.data.data;
};

interface InviteResponse {
    success: boolean;
    message: string;
    invitedUsers?: {
        email: string;
        status: string;
    }[];
}

export const invite = async (
    userID: number | null,
    emails: string[] | null,
    role: string,
    projectIDs: number[]
): Promise<InviteResponse> => {
    const response = await axios.post(`/users/invite`, {
        userID,
        emails,
        role,
        inviterUserID: 1,
        projectIDs,
    });
    return response.data.data;
};