import React, { useState } from "react";
import { RepartitionParEnergie } from "../../../api/interfaces/ApiInterfaces";
import { colorEnergies } from "../../../constants";
import WTooltip from "../../../components/WTooltip";

interface ApportFreeProps {
    repartitionParEnergie: RepartitionParEnergie[];
    vsRepartitionParEnergie?: RepartitionParEnergie[];
}

function getRepartition(
    ef: boolean | undefined,
    idTypeEnergie: number | null,
    vsRepartitionParEnergie?: RepartitionParEnergie[]
): RepartitionParEnergie | { ef: boolean | undefined; idTypeEnergie: number | null; repartition: number } {
    // Default value if no matching data is found
    const defaultRepartition = { idTypeEnergie, ef, repartition: 0 };

    if (!vsRepartitionParEnergie || idTypeEnergie === undefined) {
        return defaultRepartition;
    }

    // Find the energy type in the vsRepartitionParEnergie array
    const found = vsRepartitionParEnergie.find(
        (elm) => elm.idTypeEnergie === idTypeEnergie && elm.ef === ef
    );

    return found || defaultRepartition;
}

const EcoTypeEnergies: React.FC<ApportFreeProps> = ({ repartitionParEnergie, vsRepartitionParEnergie }) => {
    const [KWhEF, setKWhEF] = useState(true);

    // Trier les données par ordre décroissant en fonction de "repartition"
    const sortedData = repartitionParEnergie
        .filter((elm) => (KWhEF ? elm.ef : !elm.ef)) // Filtrer selon la sélection KWhEF
        .sort((a, b) => (b.repartition || 0) - (a.repartition || 0)); // Trier par ordre décroissant

    return (
        <div className="m-2 bg-white flex flex-col rounded-[10px] text-center justify-center h-1/2 flex-1">
            <div className="justify-between flex flex-col h-full p-6">
                <div className="text-left font-bold text-xl">Répartition par type d&apos;énergie</div>
                <div className="text-left text-sm mt-2 mb-3">
                    <span
                        onClick={() => setKWhEF(true)}
                        className={`hover:underline cursor-pointer ${
                            KWhEF ? "text-green-500 font-bold" : "text-black"
                        }`}
                    >
                        KWhEF
                    </span>
                    <span
                        onClick={() => setKWhEF(false)}
                        className={`hover:underline cursor-pointer ml-4 ${
                            !KWhEF ? "text-green-500 font-bold" : "text-black"
                        }`}
                    >
                        KWhEP
                    </span>
                </div>
                <div className="flex flex-col flex-1 justify-center">
                    {sortedData.map((elm, dii) => (
                        <div
                            key={dii}
                            data-tooltip-id={`tooltiprt${dii}`}
                            data-tooltip-content={`${elm.conso || 0}`}
                            data-tooltip-place="top"
                            className="flex flex-row w-full justify-between mb-1"
                        >
                            <div className="w-[25%] text-right text-nowrap truncate">
                                {elm.libTypeEnergie}
                            </div>
                            <div className="w-[55%] mx-2 h-5 bg-[#00000010] rounded relative">
                                <div
                                    style={{
                                        width: `${Math.trunc(elm.repartition || 0)}%`,
                                        backgroundColor: colorEnergies[elm.idTypeEnergie || 1],
                                    }}
                                    className="h-5 rounded z-[80] absolute top-0"
                                ></div>

                                {vsRepartitionParEnergie && (
                                    <div
                                        style={{
                                            width: `${
                                                getRepartition(
                                                    elm.ef,
                                                    elm.idTypeEnergie,
                                                    vsRepartitionParEnergie
                                                ).repartition
                                            }%`,
                                        }}
                                        className="h-5 bg-[#00000050] rounded absolute top-0 z-[70]"
                                    ></div>
                                )}
                            </div>
                            <div className="text-nowrap w-[20%] text-left pl-1">
                                {elm.repartition || 0}%
                            </div>
                            <WTooltip id={`tooltiprt${dii}`} />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default EcoTypeEnergies;
